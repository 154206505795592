export const PackageGetReducer = (state = [], action) => {

    switch (action.type) {
        case "PACKAGE_GET_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "PACKAGE_GET_SUCCESS":
            return {
                ...state,
                loading: false,
                getPackagesData: action.payload,
            };
        case "PACKAGE_GET_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "PACKAGE_GET_RESET":
            return {
                ...state,
                loading: false,
                getPackagesData: []
            };
        default:
            return state;
    }
};

export const PackageGetByIdReducer = (state = [], action) => {

    switch (action.type) {
        case "PACKAGE_GET_ID_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "PACKAGE_GET_ID_SUCCESS":
            return {
                ...state,
                loading: false,
                getPackageByIdData: action.payload,
            };
        case "PACKAGE_GET_ID_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "PACKAGE_GET_ID_RESET":
            return {
                ...state,
                loading: false,
                getPackageByIdData: []
            };
        default:
            return state;
    }
};

export const PackageCreateReducer = (state = [], action) => {

    switch (action.type) {
        case "PACKAGE_CREATE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "PACKAGE_CREATE_SUCCESS":
            return {
                ...state,
                loading: false,
                packageCreateData: action.payload,
            };
        case "PACKAGE_CREATE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "PACKAGE_CREATE_RESET":
            return {
                ...state,
                loading: false,
                packageCreateData: []
            };
        default:
            return state;
    }
};

export const PackageUpdateReducer = (state = [], action) => {

    switch (action.type) {
        case "PACKAGE_UPDATE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "PACKAGE_UPDATE_SUCCESS":
            return {
                ...state,
                loading: false,
                packageUpdateData: action.payload,
            };
        case "PACKAGE_UPDATE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "PACKAGE_UPDATE_RESET":
            return {
                ...state,
                loading: false,
                packageUpdateData: [],
                error: null
            };
        default:
            return state;
    }
};


export const PackageDeleteReducer = (state = [], action) => {

    switch (action.type) {
        case "PACKAGE_DELETE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "PACKAGE_DELETE_SUCCESS":
            return {
                ...state,
                loading: false,
                packageDeleteData: action.payload,
            };
        case "PACKAGE_DELETE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "PACKAGE_DELETE_RESET":
            return {
                ...state,
                loading: false,
                packageDeleteData: []
            };
        default:
            return state;
    }
};