import React from 'react'

const PackagesBanner = () => {
  return (
    <div className="packages_banner">
      <h2>PACKAGES</h2>
    </div>
  )
}

export default PackagesBanner