import React, { useState } from 'react'
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { useDispatch } from 'react-redux';
import { addressDetailGet } from '../../Redux/Action/Address';

const libraries = ['places'];

const GeoLocation = ({ search, setAddress, setAddressUrl, setSelectedCoordinate }) => {
    const dispatch = useDispatch();
    const [autocomplete, setAutoComplete] = useState(null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyDNAEuXsa5zDdbW8pbMxzndGu_j6rq0Ukc',
        libraries,
    });

    if (!isLoaded) {
        return <p>...Loading</p>;
    }

    const onLoad = (e) => {
        setAutoComplete(e);
    };

    let address = {}

    const onPlaceChanged = async () => {
        if (autocomplete) {

            const coordinate = {
                lat: autocomplete.getPlace().geometry.location.lat(),
                lng: autocomplete.getPlace().geometry.location.lng(),
            };

            await Promise.all(autocomplete?.getPlace()?.address_components?.map(async (a) => {
                if (a.types[0] === 'country') {
                    address["Country"] = a.long_name
                }
                if (a.types[0] === 'postal_code') {
                    address.postal = a.long_name
                }
                if (a.types[0] === 'administrative_area_level_1') {
                    address.state = a.long_name
                }
                if (a.types[0] === 'locality') {
                    address.city = a.long_name
                }
            }))

            dispatch(addressDetailGet(address))

            setSelectedCoordinate(coordinate);
            setAddress(autocomplete.getPlace().formatted_address)
            setAddressUrl(autocomplete.getPlace().url)
        }
    };

    return (
        <>
            {search ? (
                <div className={"d-flex w-100 align-items-center"}>
                    <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                        className={"w-100"}
                    >
                        <input placeholder="Property Address" />
                    </Autocomplete>
                </div>
            ) : null}
        </>
    )
}

export default GeoLocation