export const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        [{ list: "ordered" }, { list: "bullet" }]
    ]
};
export const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "color",
    "background"
]

// export const cloudURL = 'http://localhost:8083/assets/uploads'
// export const cloudURL = 'http://DESKTOP-HL6KN6S:8083/assets/uploads'
export const cloudURL = 'https://slavablazer.azurewebsites.net/assets/uploads'

export function getToken() {
    let userAuth = localStorage.getItem('User') ? JSON.parse(localStorage.getItem('User') || '') : null
    return userAuth?.data?.accessToken;
}

export function getRefreshToken() {
    let userAuth = localStorage.getItem('User') ? JSON.parse(localStorage.getItem('User') || '') : null
    return userAuth?.data?.refreshToken
};

export function updateLocalAccessToken(token) {
    let userAuth = localStorage.getItem('User') ? JSON.parse(localStorage.getItem('User') || '') : null
    userAuth.data.accessToken = token;
    localStorage.setItem("User", JSON.stringify(userAuth));
}

export function CurrentUserInfo() {
    let userAuth = localStorage.getItem('User') ? JSON.parse(localStorage.getItem('User') || '') : null
    return userAuth
}

function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} gallery_custom_slick_prev`}
            style={{
                ...style,
            }}
            onClick={onClick}
        />
    );
}

function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} gallery_custom_slick_next`}
            style={{
                ...style,
            }}
            onClick={onClick}
        />
    );
}

export const settings = {
    dots: false,
    infinite: true,
    draggable: true,
    autoplay: false,
    autoplaySpeed: 1500,
    speed: 300,
    arrow: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrow: true,
                prevArrow: <CustomPrevArrow />,
                nextArrow: <CustomNextArrow />,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrow: true,
                prevArrow: <CustomPrevArrow />,
                nextArrow: <CustomNextArrow />,
            }
        }
    ]
};

export const settings2 = {
    dots: false,
    infinite: true,
    draggable: true,
    autoplay: false,
    autoplaySpeed: 1500,
    speed: 300,
    arrow: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true,
                arrow: true,
                prevArrow: <CustomPrevArrow />,
                nextArrow: <CustomNextArrow />,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrow: true,
                prevArrow: <CustomPrevArrow />,
                nextArrow: <CustomNextArrow />,
            }
        }
    ]
};