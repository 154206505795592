import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import { errorNotify } from '../../Util/toast';

const Calender = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(null)

  const dateConvert = (d) => {
    const dateString = d;
    const date = new Date(dateString);
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate
  }

  const goToCheckout = () => {

    if (!time) {
      errorNotify("Please select date & time")
      return
    }

    let detail = {
      state,
      appointment: {
        date: dateConvert(date),
        time: time
      }
    }

    navigate("/checkout", { state: detail })

  }

  return (
    <div className='calender_main'>
      <Container>
        <Row>
          <Col md={12}>
            <div className='scheduling'>
              <Row>
                <Col md={3}>
                  <div className='schedule_shoot'>
                    <h4>Schedule Shoot</h4>
                    <p>Please select your preferred date. Our operational hours are Monday-Friday from 9:00 AM - 6:00 PM.
                      Weekend requests are subject to a $300 surcharge upon confirmation. A Project Manager will reach out
                      within 24 hours of booking to confirm your appointment or suggest an alternative date/time based on availability.</p>

                    <h6>Product</h6>

                    <h5>{state?.packageDetail?.package_name},
                      {state.addOnData.map((a) => {
                        return ` ${a.service_name ? a.service_name : a.addon_name},`
                      })}
                    </h5>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='calendery'>
                    <h4>Appointment Date</h4>
                    <Calendar onChange={setDate} value={date} />
                  </div>
                </Col>
                <Col md={3}>
                  <div className='select_time'>
                    <h4>Appointment Time</h4>
                    <h6>{dateConvert(date)} {time ? `- ${time}` : null}</h6>
                  </div>

                  <div className='select_schedule'>
                    <div onClick={() => setTime('access anytime')} className={time === 'access anytime' ? 'active' : ''}>
                      <h6>All Day</h6>
                      <p>Access anytime</p>
                    </div>

                    <div onClick={() => setTime('9:00 AM - 12:00 PM')} className={time === '9:00 AM - 12:00 PM' ? 'active' : ''}>
                      <h6>Morning</h6>
                      <p>9:00 AM - 12:00 PM</p>
                    </div>

                    <div onClick={() => setTime('2:00 PM - 5:00 PM')} className={time === '2:00 PM - 5:00 PM' ? 'active' : ''}>
                      <h6>Afternoon</h6>
                      <p>2:00 PM - 5:00 PM</p>
                    </div>

                    <div onClick={() => setTime('5:00 PM - 6:00 PM')} className={time === '5:00 PM - 6:00 PM' ? 'active' : ''}>
                      <h6>Twilight</h6>
                      <p>5:00 PM - 6:00 PM</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='checkout_div'>
              <button onClick={goToCheckout}>Checkout</button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Calender