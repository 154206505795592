export const LoginReducer = (state = [], action) => {

    switch (action.type) {
        case "LOGIN_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "LOGIN_SUCCESS":
            return {
                ...state,
                loading: false,
                loginGet: action.payload,
            };
        case "LOGIN_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "LOGIN_RESET":
            return {
                ...state,
                loading: false,
                loginGet: [],
                error: null
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};
