import { getToken } from "../../Util/helper";
import api from '../../Util/Interceptor';

export const ServicesGet = () => async (dispatch) => {

    try {
        dispatch({
            type: "SERVICES_GET_REQUEST",
        });

        const { data } = await api.get('service/list');

        dispatch({
            type: "SERVICES_GET_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "SERVICES_GET_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};

export const ServicesCreate = (d) => async (dispatch) => {

    try {
        dispatch({
            type: "SERVICES_CREATE_REQUEST",
        });

        const { data } = await api.post(`admin/service`, d, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: getToken()
            }
        });

        dispatch({
            type: "SERVICES_CREATE_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "SERVICES_CREATE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};

export const ServicesDelete = (id) => async (dispatch) => {

    try {
        dispatch({
            type: "SERVICES_DELETE_REQUEST",
        });

        const { data } = await api.delete(`admin/service/${id}`);

        dispatch({
            type: "SERVICES_DELETE_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "SERVICES_DELETE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};

export const ServiceGetById = (id) => async (dispatch) => {

    try {
        dispatch({
            type: "SERVICE_GET_ID_REQUEST",
        });

        let { data } = await api.get(`admin/service/${id}`);

        dispatch({
            type: "SERVICE_GET_ID_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "SERVICE_GET_ID_FAILED",
            payload: e?.response?.data?.message,
            success: false,
        });
    }
};

export const ServiceUpdate = (d, id) => async (dispatch) => {

    try {
        dispatch({
            type: "SERVICE_UPDATE_REQUEST",
        });

        const { data } = await api.put(`admin/service/${id}`, d, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: getToken()
            }
        });

        dispatch({
            type: "SERVICE_UPDATE_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "SERVICE_UPDATE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};