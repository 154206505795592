import React, { useEffect } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { AddOnGet, AddonDelete } from '../../../../Redux/Action/AddOn';
import Loader from '../../../../Util/Loader';
import { cloudURL } from '../../../../Util/helper';
import { errorNotify, successNotify } from '../../../../Util/toast';

const AddOns = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { getAddOnData, loading: addOnLoading } = useSelector((state) => state.addOnGet)
    const { deleteAddOnData, loading, error } = useSelector((state) => state.addOnDelete)

    useEffect(() => {
        dispatch(AddOnGet())
    }, [deleteAddOnData?.status === 1])

    useEffect(() => {
        if (deleteAddOnData?.status === 1) {
            successNotify("Deleted Successfully!")
            dispatch({ type: "ADDON_DELETE_RESET" })
        }
    }, [deleteAddOnData?.status === 1])

    useEffect(() => {
        if (error) {
            errorNotify(error)
            dispatch({ type: "ADDON_DELETE_RESET" })
        }
    }, [error])

    const deleteHandler = (id) => {
        dispatch(AddonDelete(id))
    }

    return (
        <div className='page_responsive'>
            <Container>
                <div className="create_package">
                    <button onClick={() => navigate('/admin/add-ons/create')}>Create Add Ons</button>
                </div>

                <div className="service_main p-4">
                    <div>
                        {

                            addOnLoading ? <div>
                                <Loader />
                            </div> :
                                <Row style={{ gap: "20px 0" }}>
                                    {
                                        getAddOnData?.data?.rows.length > 0 ? getAddOnData?.data?.rows?.map((a) => {
                                            return (
                                                <Col md={3} key={a.id}>
                                                    <div className="addon_div">
                                                        <img src={`${cloudURL}/addons/${a.addon_image}`} alt="" />

                                                        <div className='d-flex justify-content-between mt-3'>
                                                            <h6>{a.addon_name}</h6>
                                                            <p style={{ color: "#f48847" }}>${a.addon_price}.00</p>
                                                        </div>

                                                        <span>{a.addon_description}</span>

                                                        <hr />

                                                        <div className="d-flex gap-3 justify-content-end">
                                                            <button
                                                                style={{ padding: "4px 18px", fontSize: "13px", right: "110px" }}
                                                                onClick={() => navigate(`/admin/add-ons/edit/${a.id}`)}>Edit</button>
                                                            <button style={{ padding: "4px 18px", fontSize: "13px" }} onClick={() => deleteHandler(a.id)}>
                                                                {
                                                                    loading ? <Spinner animation="border" size="sm" /> : "Delete"
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        }) : <h5 style={{ textAlign: "center" }}>No Addons Found</h5>
                                    }
                                </Row>
                        }
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default AddOns