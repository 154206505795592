import React, { useState } from 'react'
import { Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { cloudURL } from '../../Util/helper';
import { errorNotify, successNotify } from '../../Util/toast';
import axios from 'axios';

const Checkout = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState({
        firstname: '',
        lastname: '',
        phone_number: '',
        email: '',
    })
    const [loader, setLoader] = useState(false)

    function calculateTotalPrice(packagePrice, addOns) {
        if (packagePrice) {
            let totalPrice = parseInt(packagePrice) || 0;
            addOns.forEach((addon) => {
                totalPrice += parseInt(addon.addon_price ? addon.addon_price : addon.service_price) || 0;
            });
            return totalPrice;
        }
        else {
            let totalPrice = 0;
            addOns.forEach((addon) => {
                totalPrice += parseInt(addon.addon_price ? addon.addon_price : addon.service_price) || 0;
            });
            return totalPrice;
        }
    }

    const checkoutHandler = (e) => {
        e.preventDefault();
        setLoader(true)
        if (formFields.firstname.length === 0 || formFields.lastname.length === 0 ||
            formFields.phone_number.length === 0 || formFields.email.length === 0) {
            errorNotify("Please filled all fields")
            setLoader(false)
            return
        }

        const filteredData = state.state.addOnData.filter(item => item.addon_name !== undefined);
        const filteredServiceData = state.state.addOnData.filter(item => item.service_name !== undefined);

        let data = {
            firstname: formFields.firstname,
            lastname: formFields.lastname,
            phone_number: formFields.phone_number,
            email: formFields.email,
            address: state.state.address.address ? state.state.address.address : '-',
            order_details: JSON.stringify({
                package: {
                    id: state.state.packageDetail.id,
                    price: state.state.price,
                    name: state.state.packageDetail.package_name
                },
                addons: filteredData.map((a) => {
                    return {
                        id: a.id,
                        name: a.addon_name,
                        price: a.addon_price
                    }
                }),
                services: filteredServiceData.map((s) => {
                    return {
                        id: s.id,
                        name: s.service_name,
                        price: s.service_price
                    }
                })
            }),
            city: state.state.address.getAddressDetail.city,
            state: state.state.address.getAddressDetail.state,
            country: state.state.address.getAddressDetail.Country,
            map_link: state.state.address.addressUrl ? state.state.address.addressUrl : '-',
            appointment_date: state.appointment.date,
            appointment_time: state.appointment.time,
            area: state.state.address.propertyDetails.area
        }

        axios.post('order', data).then((res) => {
            successNotify(res.data.message)
            setLoader(false)
            navigate('/')
        }).catch((e) => {
            errorNotify(e.response.data.message)
            setLoader(false)
        })
    }

    return (
        <div>
            <Container className='checkout_page mt-5'>
                <Row className="justify-content-center">
                    <Col md={8}>
                        <div className='checkout_form'>
                            <h3>Checkout</h3>

                            <Form className='coach_register make_border' onSubmit={checkoutHandler}>
                                <h5>Contact Information</h5>
                                <Row>
                                    <Col md={6} className="mb-4">
                                        <label>First Name <span>*</span></label>
                                        <input className="form-control"
                                            value={formFields.firstname}
                                            onChange={(e) => setFormFields({
                                                ...formFields,
                                                firstname: e.target.value
                                            })}
                                        />
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <label>Last Name <span>*</span></label>
                                        <input className="form-control"
                                            value={formFields.lastname}
                                            onChange={(e) => setFormFields({
                                                ...formFields,
                                                lastname: e.target.value
                                            })}
                                        />
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <label>Email <span>*</span></label>
                                        <input className="form-control" name='email'
                                            value={formFields.email}
                                            onChange={(e) => setFormFields({
                                                ...formFields,
                                                email: e.target.value
                                            })}
                                        />
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <label>Phone <span>*</span></label>
                                        <input className="form-control" name='email'
                                            value={formFields.phone_number}
                                            onChange={(e) => setFormFields({
                                                ...formFields,
                                                phone_number: e.target.value
                                            })}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <button type='submit'>{loader ? <Spinner animation='border' size='sm' /> : 'Checkout'}</button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="amount_container">
                            <div>
                                <div>
                                    <h5>- {state.state.address.address}</h5>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <h6 style={{ fontWeight: "600" }}>{state.state.packageDetail.package_name}</h6>
                                    <h6 style={{ fontWeight: "600" }}>${state.state.price}.00 </h6>
                                </div>

                                <hr />

                                {
                                    state.state.addOnData.map((a) => {
                                        return (
                                            <div className="d-flex justify-content-between mb-2 align-items-center" key={a.id}>
                                                <div className='d-flex align-items-center gap-2'>
                                                    {
                                                        a.addon_name ? <img src={`${cloudURL}/addons/${a.addon_image}`} alt=''
                                                            style={{ width: "45px", borderRadius: "5px", border: "1px solid lightgrey", height: "33px" }}
                                                        /> :
                                                            <img src={`${cloudURL}/services/${a.service_image}`}
                                                                style={{ width: "45px", borderRadius: "5px", border: "1px solid lightgrey", height: "33px" }} alt=''
                                                            />
                                                    }
                                                    <h6 style={{ fontWeight: "600" }}>{a.addon_name ? a.addon_name : a.service_name}</h6>
                                                </div>
                                                <h6 style={{ fontWeight: "600" }}>${a.addon_price ? a.addon_price : a.service_price}.00</h6>
                                            </div>
                                        )
                                    })
                                }

                                <hr />

                                <div className="d-flex justify-content-between">
                                    <h6>TOTAL AMOUNT</h6>
                                    <h6 style={{ fontWeight: "600" }}>
                                        {calculateTotalPrice(state.state.price, state.state.addOnData)}.00
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container >
        </div >
    )
}

export default Checkout