import axios from "axios";
import { getRefreshToken, getToken, updateLocalAccessToken } from "./helper";

const instance = axios.create({
    baseURL: "https://slavablazer.azurewebsites.net/api/",
    // baseURL: 'http://DESKTOP-HL6KN6S:8083/api/',
    // baseURL: 'http://localhost:8083/api/',
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig.url !== "/" && err.response) {

            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const rs = await instance.post('/auth/refreshtoken', {
                        refreshToken: getRefreshToken(),
                    });

                    const { accessToken } = rs?.data?.data;

                    if (rs?.data?.status === 1) {
                        updateLocalAccessToken(accessToken)
                    }

                    return instance(originalConfig);
                } catch (_error) {
                    localStorage.clear()
                }
            }
        }

        return Promise.reject(err);
    }
);

export default instance;
