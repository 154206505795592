import React, { useEffect } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import Loader from '../../../../Util/Loader';
import { cloudURL } from '../../../../Util/helper';
import { ServicesDelete, ServicesGet } from '../../../../Redux/Action/Services';
import { errorNotify, successNotify } from '../../../../Util/toast';

const AddOns = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { getServicesData, loading } = useSelector((state) => state.serviceGet)
    const { deleteServiceData, loading: deleteLoading, error } = useSelector((state) => state.serviceDelete)

    useEffect(() => {
        if (error) {
            errorNotify(error)
            dispatch({ type: "SERVICES_DELETE_RESET" })
        }
    }, [error])

    useEffect(() => {
        dispatch(ServicesGet())
    }, [deleteServiceData?.status === 1])

    useEffect(() => {
        if (deleteServiceData?.status === 1) {
            successNotify("Deleted Successfully!")
            dispatch({ type: "SERVICES_DELETE_RESET" })
        }
    }, [deleteServiceData?.status === 1])

    const deleteHandler = (id) => {
        dispatch(ServicesDelete(id))
    }

    return (
        <div className='page_responsive'>
            <Container>
                <div className="create_package">
                    <button onClick={() => navigate('/admin/services/create')}>Create Services</button>
                </div>

                <div className="service_main p-4">
                    <div>
                        {

                            loading ? <div>
                                <Loader />
                            </div> :
                                <Row style={{ gap: "20px 0" }}>
                                    {
                                        getServicesData?.data?.rows.length > 0 ? getServicesData?.data?.rows?.map((a) => {
                                            return (
                                                <Col md={3} key={a.id}>
                                                    <div className="addon_div">
                                                        <img src={`${cloudURL}/services/${a.service_image}`} alt="" />

                                                        <div className='d-flex justify-content-between mt-3 gap-2'>
                                                            <h6>{a.service_name}</h6>
                                                            <p style={{ color: "#f48847" }}>${a.service_price}.00</p>
                                                        </div>

                                                        <span>{a.service_description}</span>

                                                        <hr />

                                                        <div className="d-flex gap-3 justify-content-end">
                                                            <button
                                                                style={{ padding: "4px 18px", fontSize: "13px", right: "110px" }}
                                                                onClick={() => navigate(`/admin/services/edit/${a.id}`)}>Edit</button>
                                                            <button style={{ padding: "4px 18px", fontSize: "13px" }} onClick={() => deleteHandler(a.id)}>
                                                                {
                                                                    deleteLoading ? <Spinner animation="border" size="sm" /> : "Delete"
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        }) : <h5 style={{ textAlign: "center" }}>No Addons Found</h5>
                                    }
                                </Row>
                        }
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default AddOns