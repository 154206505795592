import Packages from "./Pages/Packages/Packages";
import Profile from "./Pages/Profile/Profile";
import CreatePackage from "./Pages/Packages/CreatePackage/CreatePackage";
import '../../Styles/Admin.css';
import AddOns from "./Pages/AddOns/AddOns";
import CreateAddOn from "./Pages/AddOns/CreateAddOn/CreateAddOn";
import EditPackage from "./Pages/Packages/EditPackage/EditPackage";
import CreateServices from "./Pages/Services/CreateServices/CreateServices";
import Services from "./Pages/Services/Services";
import EditAddOn from "./Pages/AddOns/EditAddOn/EditAddOn";
import EditServices from "./Pages/Services/EditServices/EditServices";

export const adminRoutes = [
    {
        path: "/admin/profile",
        component: <Profile />,
    },
    {
        path: "/admin/packages/get",
        component: <Packages />,
    },
    {
        path: "/admin/packages/create",
        component: <CreatePackage />,
    },
    {
        path: "/admin/packages/edit/:id",
        component: <EditPackage />,
    },
    {
        path: "/admin/add-ons/get",
        component: <AddOns />,
    },
    {
        path: "/admin/add-ons/create",
        component: <CreateAddOn />,
    },
    {
        path: "/admin/add-ons/edit/:id",
        component: <EditAddOn />,
    },
    {
        path: "/admin/services/get",
        component: <Services />,
    },
    {
        path: "/admin/services/create",
        component: <CreateServices />,
    },
    {
        path: "/admin/services/edit/:id",
        component: <EditServices />,
    },
];

export const AdminSideBarItems = [
    {
        path: "/admin/profile",
        icon: "",
        title: "Edit Profile",
        isSubNav: false,
    },
    {
        path: "/admin/packages/get",
        icon: "",
        title: "Package",
        isSubNav: false,
    },
    {
        path: "/admin/add-ons/get",
        icon: "",
        title: "AddOns",
        isSubNav: false,
    },
    {
        path: "/admin/services/get",
        icon: "",
        title: "Services",
        isSubNav: false,
    }
];