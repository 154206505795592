import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../Container/Admin/Navbar";

const AdminLayout = () => {
    let userAuth = localStorage.getItem("User") ? JSON.parse(localStorage.getItem("User") || "") : null;

    return userAuth?.data?.role_name === "admin" ? (
        <React.Fragment>
            <Navbar />
            <Outlet />
        </React.Fragment>
    ) : <p>Not Found</p>
};
export default AdminLayout;
