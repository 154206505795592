import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../../../Styles/Auth.css';
import { useDispatch, useSelector } from 'react-redux';
import { loginPost } from '../../../Redux/Action/Auth';
import { errorNotify, successNotify } from '../../../Util/toast';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loginGet, loading, error } = useSelector((state) => state.loginData)

    useEffect(() => {
        if (error) {
            errorNotify(error)
            dispatch({ type: 'LOGIN_RESET' })
        }
    }, [error])

    useEffect(() => {
        if (loginGet?.status === 1) {
            successNotify("Welcome!")
            navigate('/admin/profile')
            dispatch({ type: 'LOGIN_RESET' })
        }
    }, [loginGet?.status])

    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    })

    const onChangeHandler = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            username: loginData.email,
            password: loginData.password
        }

        dispatch(loginPost(data))
    }

    return (
        <Container>
            <div className='main_register_form width_register'>
                <div className='inner_register_form'>
                    <Row className='register_row'>
                        <Col md={12}>
                            <div className='coach_register'>
                                <h2 className='text-center'>Login</h2>
                                <Form onSubmit={handleSubmit}>
                                    <div className="mb-1">
                                        <Col md={12} className="mb-4">
                                            <label>Email <span>*</span></label>
                                            <input placeholder="Email" className="form-control" name='email'
                                                value={loginData.email}
                                                onChange={onChangeHandler}
                                            />
                                        </Col>
                                    </div>
                                    <div className="mb-1">
                                        <Col md={12} className="mb-4">
                                            <label>Password <span>*</span></label>
                                            <input type='password' placeholder="Password" className="form-control" name='password'
                                                value={loginData.password}
                                                onChange={onChangeHandler}
                                            />
                                        </Col>
                                    </div>
                                    <div className='text-end'>
                                        <p style={{ fontSize: "14px", color: "#002746", fontWeight: "600", cursor: "pointer" }}
                                            onClick={() => navigate('/forget-password')}
                                        >Forget Password?</p>
                                    </div>
                                    <div className='text-center'>
                                        <Button type='Submit'>
                                            {loading ? <Spinner animation='border' size='sm' /> : "Login"}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    )
}
export default Login