import axios from "axios";

export const loginPost = (d) => async (dispatch) => {

    try {
        dispatch({
            type: "LOGIN_REQUEST",
        });

        const { data } = await axios.post('auth/login', d);

        dispatch({
            type: "LOGIN_SUCCESS",
            payload: data,
            success: true,
        });

        localStorage.setItem("User", JSON.stringify(data));

    }

    catch (e) {
        dispatch({
            type: "LOGIN_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};