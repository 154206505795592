import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";
import App from "./App";
import axios from "axios";
import { Provider } from "react-redux";
import store from "./Redux/store";

// axios.defaults.baseURL = 'http://localhost:8083/api/'
// axios.defaults.baseURL = 'http://DESKTOP-HL6KN6S:8083/api/'
// axios.defaults.baseURL = 'https://slavablazer.azurewebsites.net/api/'
axios.defaults.baseURL = "https://slava-blazer-v1.azurewebsites.net/api/";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
