export const addressDetailGetReducer = (state = [], action) => {
    switch (action.type) {
        case "ADDRESS_DETAIL":
            return {
                ...state,
                getAddressDetail: action.payload,
            };
        default:
            return state;
    }
};