import { combineReducers } from "redux";
import { LoginReducer } from "./Auth";
import { PackageCreateReducer, PackageDeleteReducer, PackageGetByIdReducer, PackageGetReducer, PackageUpdateReducer } from "./Packages";
import { AddOnCreateReducer, AddOnDeleteReducer, AddOnGetByIdReducer, AddOnGetReducer, AddOnUpdateReducer } from "./AddOn";
import { ServiceGetByIdReducer, ServiceUpdateReducer, ServicesCreateReducer, ServicesDeleteReducer, ServicesGetReducer } from "./Services";
import { addressDetailGetReducer } from "./Address";

const rootReducer = combineReducers({
    loginData: LoginReducer,

    packageGet: PackageGetReducer,
    packageGetById: PackageGetByIdReducer,
    packageCreate: PackageCreateReducer,
    packageUpdate: PackageUpdateReducer,
    packageDelete: PackageDeleteReducer,

    addOnGet: AddOnGetReducer,
    addOnGetById: AddOnGetByIdReducer,
    addOnCreate: AddOnCreateReducer,
    addOnDelete: AddOnDeleteReducer,
    addOnUpdate: AddOnUpdateReducer,

    serviceGet: ServicesGetReducer,
    serviceGetById: ServiceGetByIdReducer,
    serviceCreate: ServicesCreateReducer,
    serviceDelete: ServicesDeleteReducer,
    serviceUpdate: ServiceUpdateReducer,

    addressDetail: addressDetailGetReducer
})
export default rootReducer;