import React from 'react'
import HomeBanner from '../../Components/Home/HomeBanner'
import "../../Styles/home.css"

const Home = () => {
  return (
    <div>
      <HomeBanner />
    </div>
  )
}

export default Home