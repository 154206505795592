import React, { useEffect, useState } from "react";
import { Col, Row, Container, Form, InputGroup, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { editPassword } from "../../../../Redux/Actions/login";
import { cloudURL, CurrentUserInfo, getToken } from "../../../../Util/helper";
import { errorNotify, successNotify } from '../../../../Util/toast';
import './Profile.css'

const Profile = () => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState(CurrentUserInfo()?.data?.email)

  // const { loading: editLoading, editPasswordData } = useSelector((state) => state.editPasswordApi)

  // useEffect(() => {
  //   if (editPasswordData?.success) {
  //     successNotify("Password Updated Successfully!")
  //     dispatch({ type: 'PASSWORD_EDIT_RESET' })

  //     setPasswordData({
  //       oldPassword: '',
  //       newPassword: '',
  //       confirmPassword: ''
  //     })
  //   }
  // }, [editPasswordData?.success])

  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  })

  const updatePasswordHandler = (e) => {
    e.preventDefault();
    if (passwordData?.newPassword !== passwordData?.confirmPassword) {
      errorNotify("PASSWORD MUST BE SAME")
      return
    }
    // dispatch(editPassword(passwordData, CurrentUserInfo()?._id))
  }

  const passwordChangeHandler = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <div className="page_responsive">
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <div className="profile_settings">
              <h4>Profile Settings</h4>
              <div className="profile_info">
                <Row className="profile_fields">
                  <Col md={6} className="py-2">
                    <Form.Label>Email Address</Form.Label>
                    <InputGroup>
                      <Form.Control placeholder='Enter your Email Address' value={email} onChange={(e) => setEmail(e.target.value)} disabled />
                    </InputGroup>
                  </Col>
                </Row>
              </div>

              <div className="profile_info mt-5">
                <h4>CHANGE PASSWORD</h4>

                <Form onSubmit={updatePasswordHandler}>
                  <Row className="profile_fields">
                    <Col md={4} className="py-2">
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <Form.Control type="password" placeholder='Enter your Old Password' name="oldPassword" value={passwordData.oldPassword} onChange={passwordChangeHandler} />
                      </InputGroup>
                    </Col>

                    <Col md={4} className="py-2">
                      <Form.Label>New Password</Form.Label>
                      <InputGroup>
                        <Form.Control type="password" placeholder='Enter your New Password' name="newPassword" value={passwordData.newPassword} onChange={passwordChangeHandler} />
                      </InputGroup>
                    </Col>

                    <Col md={4} className="py-2">
                      <Form.Label>Confirm Password</Form.Label>
                      <InputGroup>
                        <Form.Control type="password" placeholder='Enter your Confirm Password' name="confirmPassword" value={passwordData.confirmPassword} onChange={passwordChangeHandler} />
                      </InputGroup>
                    </Col>

                    <Col md={12}>
                      {/* <div className="text-end"> <Button type='Submit'>{editLoading ? <Spinner animation="border" size="sm" /> : 'Update Password'}</Button> </div> */}
                      <div className="text-end"> <Button type='Submit'>Update Password</Button> </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Profile;