import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "../../Styles/header.css"
import { HiMail } from "react-icons/hi"
import { ImMobile } from "react-icons/im"
import { useNavigate } from 'react-router-dom';
import { CurrentUserInfo } from '../../Util/helper';
import { NavDropdown } from 'react-bootstrap';
import { successNotify } from '../../Util/toast';

function Header() {
  const navigate = useNavigate();

  const logoutHandler = () => {
    localStorage.removeItem("User");
    successNotify("Logout Successfully!")
    navigate("/");
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">Slava Blazer Photography</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <div >
              <HiMail />
              <div>
                <p>EMAIL US</p>
                <h6>hello@slavablazer.com</h6>
              </div>
            </div>
            <div>
              <ImMobile />
              <div>
                <p>CALL US NOW</p>
                <h6>415-269-6347</h6>
              </div>
            </div>

            {
              CurrentUserInfo() ? <div className="d-flex align-items-center login_nav">
                <NavDropdown title="Admin" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#" onClick={() => navigate('/admin/profile')}>
                    Go to Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
                : <div>
                  <button onClick={() => navigate('/login')}>LOGIN</button>
                </div>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;