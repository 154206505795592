import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { ImCross } from "react-icons/im";
import "./Sidebar.css";

const DashboardSidebar = (props) => {
    const location = useLocation();
    const [sideBar, setSideBar] = useState(true);

    const classes = (path) => {
        let splitPath = path.split('/')
        let locationPath = location.pathname.split('/')

        if (splitPath[2] === locationPath[2]) return "nav_active";
        return "";
    };

    return (
        <div className={sideBar ? "sidebar active" : "sidebar"}>
            <div className={"logo_content"}>
                {sideBar ? <ImCross className={"fa-bars"} onClick={() => setSideBar(false)} />
                    : <FaIcons.FaBars
                        className={"fa-bars"}
                        onClick={() => setSideBar(true)}
                    />}
            </div>
            <ul className="nav_list">
                {props?.sideBarItems?.map((item) => {
                    return (
                        <li key={item.path} className={`${classes(item.path)}`}>
                            <Link to={item.path}>
                                {item.icon}
                                <span>{item.title}</span>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
export default DashboardSidebar;