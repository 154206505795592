import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Util/Loader";
import { cloudURL } from "../../Util/helper";
import { errorNotify, successNotify } from "../../Util/toast";
import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import { PackageGetById } from "../../Redux/Action/Packages";
import { AddOnGet } from "../../Redux/Action/AddOn";
import { settings } from '../../Util/helper';

const Services = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [tab, setTab] = useState("INCLUDED")
    const [addOns, setAddOns] = useState([])

    const { getPackageByIdData, loading } = useSelector((state) => state.packageGetById)
    const { getAddOnData, loading: addOnLoading } = useSelector((state) => state.addOnGet)

    const addAddOnsHandler = (data) => {
        for (let value in addOns) {
            if (addOns[value].id === data.id) {
                errorNotify("This Addons is already added!")
                return;
            }
        }

        setAddOns((prev) => [...prev, data])
        successNotify("AddOns Added Successfully!")
    }

    useEffect(() => {
        if (state?.id) {
            dispatch(PackageGetById(state.id))
            setAddOns(state?.addOnData)
        }
    }, [state?.id])

    useEffect(() => {
        dispatch(AddOnGet())
    }, [])

    function calculateTotalPrice(packagePrice, addOns) {
        let totalPrice = parseInt(packagePrice) || 0;

        addOns.forEach((addon) => {
            if (addon.addon_price) {
                totalPrice += parseInt(addon.addon_price) || 0;
            }
            else {
                totalPrice += parseInt(addon.service_price) || 0;
            }
        });

        return totalPrice;
    }

    const addOnDeleteHandler = (i) => {
        const newAddOns = addOns.filter((_, index) => index !== i);
        setAddOns(newAddOns)
        successNotify("Deleted Successfully!")
    }

    const checkoutHandler = () => {
        navigate("/scheduling", { state: { packageDetail: getPackageByIdData?.data, address: state.packageDetail, addOnData: addOns, price: state?.price } })
    }

    return (
        <div className="py-5" style={{ backgroundColor: "#f8f9fa" }}>
            {
                loading ? <div>
                    <Loader />
                </div> :
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={7}>
                                <div>
                                    <div className="service_main">
                                        <div className="saving"><h6>SAVING $60.00</h6></div>
                                        <img src={`${cloudURL}/packages/${getPackageByIdData?.data.package_banner}`} alt="" />
                                        <div className="mx-4 my-2">
                                            <h2>{getPackageByIdData?.data.package_name}</h2>

                                            <div className="package_price">
                                                ${state?.price}.00
                                            </div>
                                            <p className="service_info">
                                                {getPackageByIdData?.data.package_description}
                                            </p>
                                            <hr />

                                            <div className="service_tabs">
                                                <button className={tab === 'INCLUDED' ? "active" : ""} onClick={() => setTab("INCLUDED")}>What's Included</button>
                                                <button className={tab === 'ABOUT' ? "active" : ""} onClick={() => setTab("ABOUT")}>About Package</button>
                                            </div>

                                            {
                                                tab === 'ABOUT' && <div>

                                                    <div>
                                                        <h5 style={{
                                                            fontSize: "27px",
                                                            marginBottom: "10px",
                                                            fontWeight: "600",
                                                            textTransform: "uppercase",
                                                            textAlign: "center"
                                                        }}>Gallery</h5>
                                                        <Slider {...settings}>
                                                            {
                                                                getPackageByIdData?.data?.Media.map((i, index) => {
                                                                    return (
                                                                        <div key={index} style={{ position: "relative" }}>
                                                                            <img src={`${cloudURL}/gallery/${i.filename}`} alt=""
                                                                                style={{ maxWidth: "100%", height: "400px", objectFit: "cover", width: "100%" }} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Slider>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                tab === 'INCLUDED' && <div className="package_included">
                                                    <h2 style={{ textTransform: "uppercase" }} className="mb-3">In Package</h2>
                                                    <Row className="benefits">
                                                        {
                                                            getPackageByIdData?.data.Services.map((a) => (
                                                                <Col md={4} key={a.id}>
                                                                    <img src={`${cloudURL}/services/${a.service_image}`} alt="" />
                                                                    <h6 style={{ fontSize: "15px", marginTop: "8px", fontWeight: "600" }}>{a.service_name}</h6>
                                                                </Col>
                                                            ))
                                                        }
                                                    </Row>
                                                </div>
                                            }
                                            <hr />

                                        </div>
                                    </div>

                                    <div className="service_main p-4">
                                        <div>
                                            <h2>Select Add-ons</h2>

                                            {
                                                addOnLoading ? <div>
                                                    <Loader />
                                                </div> :
                                                    <Row className="mt-3" style={{ gap: "20px 0" }}>
                                                        {
                                                            getAddOnData?.data?.rows?.map((a) => {
                                                                return (
                                                                    <Col md={4} key={a.id}>
                                                                        <div className="addon_div">
                                                                            <img src={`${cloudURL}/addons/${a.addon_image}`} alt="" />
                                                                            <h6>{a.addon_name}</h6>
                                                                            <p style={{ color: "#f48847" }}>${a.addon_price}.00</p>
                                                                            <button onClick={() => addAddOnsHandler(a)}>+</button>
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="amount_container">
                                    <div>
                                        <div>
                                            <h5>- {state?.packageDetail?.address}</h5>
                                        </div>
                                        <hr />

                                        <div className="d-flex justify-content-between">
                                            <h6 style={{ fontWeight: "600" }}>{getPackageByIdData?.data.package_name}</h6>
                                            <h6 style={{ fontWeight: "600" }}>${state?.price}.00 </h6>
                                        </div>

                                        <hr />

                                        {
                                            addOns.map((a, i) => {
                                                return (
                                                    <div className="d-flex justify-content-between mb-2" key={a.id}>
                                                        <h6 style={{ fontWeight: "600" }}>{a.addon_name ? a.addon_name : a.service_name}</h6>
                                                        <h6 style={{ fontWeight: "600" }}>${a.addon_price ? a.addon_price : a.service_price}.00
                                                            <span onClick={() => addOnDeleteHandler(i)}>
                                                                <img src="/images/delete_icon.png" alt="" style={{ cursor: "pointer", width: "25px", paddingLeft: "10px" }} /></span>
                                                        </h6>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <hr />
                                    <div className="d-flex justify-content-between">
                                        <h6>TOTAL AMOUNT</h6>
                                        <h6 style={{ fontWeight: "600" }}>
                                            $ {calculateTotalPrice(state?.price, addOns)}.00
                                        </h6>
                                    </div>
                                    <hr />
                                    <button onClick={checkoutHandler}>Continue</button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
            }
        </div>
    );
};
export default Services;