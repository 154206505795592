export const ServicesGetReducer = (state = [], action) => {

    switch (action.type) {
        case "SERVICES_GET_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "SERVICES_GET_SUCCESS":
            return {
                ...state,
                loading: false,
                getServicesData: action.payload,
            };
        case "SERVICES_GET_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "SERVICES_GET_RESET":
            return {
                ...state,
                loading: false,
                getServicesData: []
            };
        default:
            return state;
    }
};

export const ServicesCreateReducer = (state = [], action) => {

    switch (action.type) {
        case "SERVICES_CREATE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "SERVICES_CREATE_SUCCESS":
            return {
                ...state,
                loading: false,
                createServiceData: action.payload,
            };
        case "SERVICES_CREATE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "SERVICES_CREATE_RESET":
            return {
                ...state,
                loading: false,
                createServiceData: []
            };
        default:
            return state;
    }
};

export const ServicesDeleteReducer = (state = [], action) => {

    switch (action.type) {
        case "SERVICES_DELETE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "SERVICES_DELETE_SUCCESS":
            return {
                ...state,
                loading: false,
                deleteServiceData: action.payload,
            };
        case "SERVICES_DELETE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "SERVICES_DELETE_RESET":
            return {
                ...state,
                loading: false,
                deleteServiceData: [],
                error: null
            };
        default:
            return state;
    }
};

export const ServiceGetByIdReducer = (state = [], action) => {

    switch (action.type) {
        case "SERVICE_GET_ID_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "SERVICE_GET_ID_SUCCESS":
            return {
                ...state,
                loading: false,
                getServiceIdData: action.payload,
            };
        case "SERVICE_GET_ID_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "SERVICE_GET_ID_RESET":
            return {
                ...state,
                loading: false,
                getServiceIdData: []
            };
        default:
            return state;
    }
};

export const ServiceUpdateReducer = (state = [], action) => {

    switch (action.type) {
        case "SERVICE_UPDATE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "SERVICE_UPDATE_SUCCESS":
            return {
                ...state,
                loading: false,
                updateServiceData: action.payload,
            };
        case "SERVICE_UPDATE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "SERVICE_UPDATE_RESET":
            return {
                ...state,
                loading: false,
                updateServiceData: []
            };
        default:
            return state;
    }
};