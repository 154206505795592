import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SearchBox from "./SearchBox";

const HomeBanner = () => {
  return (
    <div className="home_banner">
      <Container>
        <h2>BOOK YOUR MEDIA SERVICES</h2>
        <div>
          <Row className="justify-content-center">
            <Col md={11}>
              <SearchBox />
            </Col>
          </Row>
        </div>
      </Container>
      {/* <button className="scroll_btn">
        <img src="/images/scroll-btn.png" alt="" />
      </button> */}
    </div>
  );
};

export default HomeBanner;
