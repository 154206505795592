import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import ImageUploader from 'react-images-upload';
import { useDispatch, useSelector } from 'react-redux';
import { PackageGetById, PackageUpdate } from '../../../../../Redux/Action/Packages';
import { errorNotify, successNotify } from '../../../../../Util/toast';
import { useNavigate, useParams } from 'react-router-dom';
import { cloudURL } from '../../../../../Util/helper';
import Select from 'react-select'
import { ServicesGet } from '../../../../../Redux/Action/Services';

const EditPackage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const [packageData, setPackageData] = useState({
        packageName: '',
        packagePrice: '',
        packageDsc: '',
        package_saving: ''
    })
    const [file, setFile] = useState(null)
    const [preview, setPreview] = useState(null)
    const [images, setImages] = useState([]);
    const [galleryImages, setGalleryImages] = useState([])
    const [priceTag, setPriceTag] = useState([{
        min_area: 0,
        max_area: 999,
        price: 0
    }])
    const [AddonsValues, setAddonsValues] = useState([]);

    const { packageUpdateData, loading, error } = useSelector((state) => state.packageUpdate)
    const { getPackageByIdData } = useSelector((state) => state.packageGetById)
    const { getServicesData } = useSelector((state) => state.serviceGet)

    useEffect(() => {
        dispatch(PackageGetById(id))
    }, [])

    useEffect(() => {
        dispatch(ServicesGet())
    }, [])

    useEffect(() => {
        if (getPackageByIdData) {

            setPackageData({
                packageName: getPackageByIdData?.data.package_name,
                packagePrice: getPackageByIdData?.data.package_price,
                packageDsc: getPackageByIdData?.data.package_description,
                package_saving: getPackageByIdData?.data.package_saving
            })
            setFile(getPackageByIdData?.data.package_banner)

            let arr = []
            getPackageByIdData?.data.Media.map((m) => {
                return arr.push(`${cloudURL}/gallery/${m.filename}`)
            })
            setImages(arr)

            setPriceTag(getPackageByIdData?.data.Prices)

            const defaultIds = getPackageByIdData.data.Services.map((p) => p.id);
            setAddonsValues(defaultIds);

        }
    }, [getPackageByIdData])

    useEffect(() => {
        if (packageUpdateData?.status === 1) {
            successNotify("Package Updated!!")
            dispatch({ type: "PACKAGE_UPDATE_RESET" })
            navigate('/admin/packages/get')
        }
        else if (error) {
            errorNotify(error)
            dispatch({ type: "PACKAGE_UPDATE_RESET" })
        }
    }, [packageUpdateData?.status, error])

    const packageHandler = (e) => {
        setPackageData({
            ...packageData,
            [e.target.name]: e.target.value
        })
    }

    const packageCreateHandler = (e) => {
        e.preventDefault();

        if (packageData.packageName.length === 0 || packageData.package_saving.length === 0 || AddonsValues.length === 0) {
            errorNotify("Please Filled up all fields")
            return
        }

        const commaSeparatedValues = AddonsValues.join(',');

        const packageForm = new FormData();
        packageForm.append('package_name', packageData.packageName)
        packageForm.append('package_type', 1)
        packageForm.append('package_description', packageData.packageDsc)
        packageForm.append('package_saving', packageData.package_saving)
        typeof file !== 'string' && packageForm.append('package_banner', file)
        packageForm.append('prices', JSON.stringify(priceTag))
        packageForm.append('services', commaSeparatedValues)
        packageForm.append('tags', [])
        packageForm.append('addons', [4, 5, 2])
        packageForm.append('package_price', packageData.packagePrice)
        galleryImages.length !== 0 && galleryImages.map((image) => packageForm.append("gallery", image))

        dispatch(PackageUpdate(packageForm, id))
    }

    const handleAddClick = () => {

        const priceTagShow = priceTag[priceTag.length - 1];

        if (priceTagShow.min_area.length !== 0 && priceTagShow.max_area.length !== 0 && priceTagShow.price.length !== 0) {
            if (parseInt(priceTagShow.min_area) < parseInt(priceTagShow.max_area)) {
                setPriceTag([
                    ...priceTag,
                    {
                        min_area: parseInt(priceTagShow.max_area) + 1,
                        max_area: priceTag.max_area,
                        price: priceTag.price
                    }
                ])
            }
            else {
                errorNotify("Max SqFt must be greater than Min SqFt")
                return
            }
        }
        else {
            errorNotify("Fields cannot be empty")
        }
    };

    const priceTagHandler = (e, index, name) => {
        const list = [...priceTag];
        list[index][name] = e.target.value;
        setPriceTag(list);
    };

    const options = getServicesData?.data?.rows.map((a) => {
        return {
            value: a.id,
            label: a.service_name
        }
    })

    const handleChange = (selectedOptions) => {
        const selectedIds = selectedOptions.map((option) => option.value);
        setAddonsValues(selectedIds);
    };

    return (
        <div className='page_responsive'>
            <div className='create_package_form'>
                <h3 className='main_head'>Edit Package</h3>
                <Form onSubmit={packageCreateHandler}>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Package Banner</Form.Label>
                                <Form.Control type="file" onChange={(e) => {
                                    setFile(e.target.files[0])
                                    setPreview(URL.createObjectURL(e.target.files[0]))
                                }} />
                            </Form.Group>
                            {
                                typeof file === 'string' ? <div className='preview_img mb-3'>
                                    <img src={`${cloudURL}/packages/${file}`} alt='' />
                                </div> :
                                    <div className='preview_img mb-3'>
                                        <img src={preview} alt='' />
                                    </div>
                            }
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Package Name</Form.Label>
                                <Form.Control type="text" placeholder="Package Name" name='packageName'
                                    value={packageData.packageName} onChange={packageHandler} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Package Price</Form.Label>
                                <Form.Control type="text" placeholder="Package Price" name='packagePrice'
                                    value={priceTag[0].price} onChange={(e) => priceTagHandler(e, 0, "price")} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Package Saving Price</Form.Label>
                                <Form.Control type="text" placeholder="Package Saving Price" name='package_saving'
                                    value={packageData.package_saving} onChange={packageHandler} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Package Description</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="Package Description" name='packageDsc'
                                    value={packageData.packageDsc} onChange={packageHandler} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Price Tag</Form.Label>

                                {
                                    priceTag.map((c, i) => {
                                        return (
                                            <div className='price_tag' key={c}>
                                                <div>
                                                    <label>Min SqFt</label>
                                                    <Form.Control type="text" placeholder="Starting Square Feet" name='min_area'
                                                        value={c.min_area}
                                                        disabled
                                                        onChange={(e) => priceTagHandler(e, i, "min_area")}
                                                    />
                                                </div>
                                                <div>
                                                    <label>Max SqFt</label>
                                                    <Form.Control type="text" placeholder="Ending Sqaure Feet" name='max_area'
                                                        value={c.max_area}
                                                        onChange={(e) => priceTagHandler(e, i, "max_area")}
                                                    />
                                                </div>
                                                <div>
                                                    <label>Price</label>
                                                    <Form.Control type="text" placeholder="Price" name='price'
                                                        value={c.price}
                                                        onChange={(e) => priceTagHandler(e, i, "price")}
                                                    />
                                                </div>

                                                {priceTag.length - 1 === i ? (
                                                    <button type="button" onClick={handleAddClick}>Add More</button>
                                                ) : null}
                                            </div>
                                        )
                                    })
                                }
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Select Services</Form.Label>
                                <Select
                                    value={AddonsValues.map((id) =>
                                        options?.find((option) => option.value === id)
                                    )}
                                    placeholder='Select Services' options={options} isMulti onChange={handleChange} />
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Label>Gallery Images</Form.Label>
                            <ImageUploader
                                withIcon={true}
                                buttonText='Choose Gallery images'
                                onChange={(pictureFiles) => {
                                    setGalleryImages(pictureFiles)
                                }}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                maxFileSize={5242880}
                                withPreview={true}
                                defaultImages={images}
                            />
                        </Col>
                        <Col md={12} className='mt-3'>
                            <div className='create_btn'>
                                <button type='submit'>{loading ? <Spinner animation='border' size='sm' /> : "Edit"}</button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default EditPackage