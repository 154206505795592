import React from 'react'
import PackagesBanner from '../../Components/Packages/PackagesBanner'
import "../../Styles/packages.css"
import Services from '../../Components/Packages/Services'
const Packages = () => {
  return (
    <div>
      <PackagesBanner />
      <Services />
    </div>
  )
}

export default Packages