export const AddOnGetReducer = (state = [], action) => {

    switch (action.type) {
        case "ADD_ON_GET_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "ADD_ON_GET_SUCCESS":
            return {
                ...state,
                loading: false,
                getAddOnData: action.payload,
            };
        case "ADD_ON_GET_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "ADD_ON_GET_RESET":
            return {
                ...state,
                loading: false,
                getAddOnData: []
            };
        default:
            return state;
    }
};

export const AddOnCreateReducer = (state = [], action) => {

    switch (action.type) {
        case "ADDON_CREATE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "ADDON_CREATE_SUCCESS":
            return {
                ...state,
                loading: false,
                createAddOnData: action.payload,
            };
        case "ADDON_CREATE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "ADDON_CREATE_RESET":
            return {
                ...state,
                loading: false,
                createAddOnData: []
            };
        default:
            return state;
    }
};

export const AddOnDeleteReducer = (state = [], action) => {

    switch (action.type) {
        case "ADDON_DELETE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "ADDON_DELETE_SUCCESS":
            return {
                ...state,
                loading: false,
                deleteAddOnData: action.payload,
            };
        case "ADDON_DELETE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "ADDON_DELETE_RESET":
            return {
                ...state,
                loading: false,
                deleteAddOnData: [],
                error: null
            };
        default:
            return state;
    }
};

export const AddOnGetByIdReducer = (state = [], action) => {

    switch (action.type) {
        case "ADD_ON_GET_ID_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "ADD_ON_GET_ID_SUCCESS":
            return {
                ...state,
                loading: false,
                getAddOnIdData: action.payload,
            };
        case "ADD_ON_GET_ID_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "ADD_ON_GET_ID_RESET":
            return {
                ...state,
                loading: false,
                getAddOnIdData: []
            };
        default:
            return state;
    }
};

export const AddOnUpdateReducer = (state = [], action) => {

    switch (action.type) {
        case "ADDON_UPDATE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "ADDON_UPDATE_SUCCESS":
            return {
                ...state,
                loading: false,
                updateAddOnData: action.payload,
            };
        case "ADDON_UPDATE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "ADDON_UPDATE_RESET":
            return {
                ...state,
                loading: false,
                updateAddOnData: []
            };
        default:
            return state;
    }
};