import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import ImageUploader from 'react-images-upload';
import { useDispatch, useSelector } from 'react-redux';
import { PackageCreate } from '../../../../../Redux/Action/Packages';
import { errorNotify, successNotify } from '../../../../../Util/toast';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import { ServicesGet } from '../../../../../Redux/Action/Services';

const CreatePackage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [packageData, setPackageData] = useState({
        packageName: '',
        packagePrice: '',
        packageDsc: '',
        package_saving: ''
    })
    const [file, setFile] = useState(null)
    const [images, setImages] = useState([]);
    const [AddonsValues, setAddonsValues] = useState([]);
    const [priceTag, setPriceTag] = useState([
        {
            min_area: 0,
            max_area: 999,
            price: 1
        }
    ])

    const { packageCreateData, loading } = useSelector((state) => state.packageCreate)
    const { getServicesData } = useSelector((state) => state.serviceGet)

    useEffect(() => {
        dispatch(ServicesGet())
    }, [])

    useEffect(() => {
        if (packageCreateData?.status === 1) {
            successNotify("Package Created!!")
            dispatch({ type: "PACKAGE_CREATE_RESET" })
            navigate('/admin/packages/get')
        }
    }, [packageCreateData])

    const packageHandler = (e) => {
        setPackageData({
            ...packageData,
            [e.target.name]: e.target.value
        })
    }

    const options = getServicesData?.data?.rows.map((a) => {
        return {
            value: a.id,
            label: a.service_name
        }
    })

    const handleChange = (selectedOptions) => {
        const selectedIds = selectedOptions.map((option) => option.value);
        setAddonsValues(selectedIds);
    };

    const packageCreateHandler = (e) => {
        e.preventDefault();

        if (packageData.packageName.length === 0 || packageData.package_saving.length === 0 || AddonsValues.length === 0 || !file) {

            errorNotify("Please Filled up all fields")
            return
        }

        const commaSeparatedValues = AddonsValues.join(',');

        const packageForm = new FormData();
        packageForm.append('package_name', packageData.packageName)
        packageForm.append('package_type', 1)
        packageData.packageDsc.length !== 0 && packageForm.append('package_description', packageData.packageDsc) 
        packageForm.append('package_saving', packageData.package_saving)
        packageForm.append('package_banner', file)
        packageForm.append('tags', [])
        packageForm.append('prices', JSON.stringify(priceTag))
        packageForm.append('services', commaSeparatedValues)
        packageForm.append('package_price', priceTag[0].price)
        images.length > 0 && images.map((image) => packageForm.append("gallery", image))

        dispatch(PackageCreate(packageForm))
    }

    const priceTagHandler = (e, index, name) => {
        const list = [...priceTag];
        list[index][name] = e.target.value;
        setPriceTag(list);
    };

    // handle click event of the Remove button
    // const handleRemoveClick = (index: any) => {
    //   const list = [...timings];
    //   list.splice(index, 1);
    //   setTimings(list);
    // };

    const handleAddClick = () => {

        const priceTagShow = priceTag[priceTag.length - 1];

        if (priceTagShow?.max_area || priceTagShow?.price) {
            if (parseInt(priceTagShow.min_area) < parseInt(priceTagShow.max_area)) {
                if (priceTagShow?.price > 0) {
                    setPriceTag([
                        ...priceTag,
                        {
                            min_area: parseInt(priceTagShow.max_area) + 1,
                            max_area: priceTag.max_area,
                            price: priceTag.price
                        }
                    ])
                }
                else {
                    errorNotify("Price must be greater than zero")
                }
            }
            else {
                errorNotify("Max SqFt must be greater than Min SqFt")
                return
            }
        }
        else {
            errorNotify("Please fill up all fields")
        }
    };

    return (
        <div className='page_responsive'>
            <div className='create_package_form'>
                <h3 className='main_head'>Create Package</h3>
                <Form onSubmit={packageCreateHandler}>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Package Banner</Form.Label>
                                <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} />
                            </Form.Group>
                            {
                                file && <div className='preview_img'>
                                    <img src={URL.createObjectURL(file)} alt='' />
                                </div>
                            }
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Package Name</Form.Label>
                                <Form.Control type="text" placeholder="Package Name" name='packageName'
                                    value={packageData.packageName} onChange={packageHandler} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Package Price</Form.Label>
                                <Form.Control type="text" placeholder="Package Price" name='packagePrice'
                                    value={priceTag[0].price} onChange={(e) => priceTagHandler(e, 0, "price")} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Package Saving Price</Form.Label>
                                <Form.Control type="text" placeholder="Package Saving Price" name='package_saving'
                                    value={packageData.package_saving} onChange={packageHandler} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Package Description</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="Package Description" name='packageDsc'
                                    value={packageData.packageDsc} onChange={packageHandler} />
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Price Tag</Form.Label>

                                {
                                    priceTag.map((c, i) => {
                                        return (
                                            <div className='price_tag' key={c}>
                                                <div>
                                                    <label>Min SqFt</label>
                                                    <Form.Control type="text" placeholder="Starting Square Feet" name='min_area'
                                                        value={c.min_area}
                                                        disabled
                                                        onChange={(e) => priceTagHandler(e, i, "min_area")}
                                                    />
                                                </div>
                                                <div>
                                                    <label>Max SqFt</label>
                                                    <Form.Control type="text" placeholder="Ending Sqaure Feet" name='max_area'
                                                        value={c.max_area}
                                                        onChange={(e) => priceTagHandler(e, i, "max_area")}
                                                    />
                                                </div>
                                                <div>
                                                    <label>Price</label>
                                                    <Form.Control type="text" placeholder="Price" name='price'
                                                        value={c.price}
                                                        onChange={(e) => priceTagHandler(e, i, "price")}
                                                    />
                                                </div>

                                                {priceTag.length - 1 === i ? (
                                                    <button type="button" onClick={handleAddClick}>Add More</button>
                                                ) : null}
                                            </div>
                                        )
                                    })
                                }
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Select Services</Form.Label>
                                <Select placeholder='Select Services' options={options} isMulti onChange={handleChange} />
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Label>Gallery Images</Form.Label>
                            <ImageUploader
                                withIcon={true}
                                buttonText='Choose Gallery images'
                                onChange={(pictureFiles) => setImages(pictureFiles)}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                maxFileSize={5242880}
                                withPreview={true}
                            />
                        </Col>
                        <Col md={12} className='mt-3'>
                            <div className='create_btn'>
                                <button type='submit'>{loading ? <Spinner animation='border' size='sm' /> : "Create"}</button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default CreatePackage