import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";

const MainLayout = () => {
    return (
        <React.Fragment>
            <Outlet />
            {/* <Footer /> */}
        </React.Fragment>
    );
};
export default MainLayout;
