import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import GeoLocation from '../GeoLocation/GeoLocation';
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { FaEdit } from 'react-icons/fa';
import { errorNotify } from '../../Util/toast';
import SiteModal from '../Modal/Modal';
import { useSelector } from 'react-redux';

const SearchBox = () => {
  const navigate = useNavigate();
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState({
    floor: "",
    area: null
  })
  const [address, setAddress] = useState(null);
  const [addressUrl, setAddressUrl] = useState();
  const [manualDetails, setManualDetails] = useState({
    address: '',
    country: '',
    state: '',
    city: '',
    postalCode: ''
  })

  const { getAddressDetail } = useSelector((state) => state.addressDetail)

  const [show, setShow] = useState(false)

  const PropertyHandler = (e) => {
    e.preventDefault();

    if (propertyDetails?.area?.length === 0 || !selectedCoordinates) {
      errorNotify("Please Fill required Fields")
      return
    }

    const mergeData = {
      address,
      addressUrl,
      selectedCoordinates,
      propertyDetails,
      getAddressDetail
    }

    navigate('/packages', { state: { propertyData: mergeData } })
  }

  const manualAddressHandler = (e) => {
    e.preventDefault();

    if (propertyDetails?.area?.length === 0 || manualDetails.address.length === 0 || manualDetails.state.length === 0 ||
      manualDetails.country.length === 0 || manualDetails.postalCode.length === 0
    ) {
      errorNotify("Please Fill required Fields")
      return
    }

    let makeAddress = `${manualDetails.address} ${manualDetails.city}, ${manualDetails.state}, ${manualDetails.country}, ${manualDetails.postalCode}`

    const mergeData = {
      address: makeAddress,
      addressUrl: null,
      selectedCoordinates: null,
      propertyDetails
    }

    navigate('/packages', { state: { propertyData: mergeData } })
  }

  const modal = <SiteModal title="Enter Property Address" show={show} size="lg" onCloseModal={() => setShow(!show)} showIcon={true} >
    <div className='location_modal'>
      <Form onSubmit={manualAddressHandler}>
        <Row>
          <Col md={12}>
            <input placeholder="Property Address" value={manualDetails.address} onChange={(e) => setManualDetails({
              ...manualDetails,
              address: e.target.value
            })} />
          </Col>
          <Col md={6} xs={6}>
            <input placeholder="Country" value={manualDetails.country} onChange={(e) => setManualDetails({
              ...manualDetails,
              country: e.target.value
            })} />
          </Col>
          <Col md={6} xs={6}>
            <input placeholder="State" value={manualDetails.state} onChange={(e) => setManualDetails({
              ...manualDetails,
              state: e.target.value
            })} />
          </Col>
          <Col md={6} xs={6}>
            <input placeholder="City" value={manualDetails.city} onChange={(e) => setManualDetails({
              ...manualDetails,
              city: e.target.value
            })} />
          </Col>
          <Col md={6} xs={6}>
            <input placeholder="Zip Code" value={manualDetails.postalCode} onChange={(e) => setManualDetails({
              ...manualDetails,
              postalCode: e.target.value
            })} />
          </Col>
          <Col md={6} xs={6}>
            <input placeholder="Apartment, Floor" value={propertyDetails.floor} onChange={(e) => setPropertyDetails({
              ...propertyDetails,
              floor: e.target.value
            })} />
          </Col>
          <Col md={6} xs={6}>
            <input type='number' placeholder="Total interior area" value={propertyDetails.area}
              onChange={(e) => setPropertyDetails({
                ...propertyDetails,
                area: e.target.value
              })} />
          </Col>
          <Col md={12}>
            <button type='submit' className='modal_property'>CONFIRM PROPERTY</button>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col md={12}>
          <GoogleMap
            center={{
              lat: 10,
              lng: 10
            }}
            zoom={15}
            mapContainerStyle={{
              width: "100%",
              height: "300px",
              marginTop: "10px",
            }}
          >
            <MarkerF
              icon={{
                url: "data:image/svg+xml;base64,PHN2ZyBiYXNlUHJvZmlsZT0iZnVsbCIgd2lkdGg9Ijg2IiBoZWlnaHQ9Ijg2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPGRlZnM+CiAgICAgICAgPGZpbHRlciBpZD0iYSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4KICAgICAgICAgICAgPGZlRHJvcFNoYWRvdyBkeD0iMCIgZHk9Ii41IiBzdGREZXZpYXRpb249Ii45IiBmbG9vZC1jb2xvcj0iIzkzOTM5OCIvPgogICAgICAgIDwvZmlsdGVyPgogICAgPC9kZWZzPgogICAgPGNpcmNsZSBjeD0iNDMiIGN5PSI0MyIgcj0iOCIgZmlsbD0iIzk0YzdmZiI+CiAgICAgICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iciIgZnJvbT0iMTEiIHRvPSI0MCIgZHVyPSIycyIgYmVnaW49IjBzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIvPgogICAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9wYWNpdHkiIGZyb209IjEiIHRvPSIwIiBkdXI9IjJzIiBiZWdpbj0iMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+CiAgICA8L2NpcmNsZT4KICAgIDxjaXJjbGUgY3g9IjQzIiBjeT0iNDMiIHI9IjgiIGZpbGw9IiNmZmYiIGZpbHRlcj0idXJsKCNhKSIvPgogICAgPGNpcmNsZSBjeD0iNDMiIGN5PSI0MyIgcj0iNSIgZmlsbD0iIzAxN2FmZiI+CiAgICAgICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iciIgdmFsdWVzPSI1OzYuNTs1IiBiZWdpbj0iMHMiIGR1cj0iNC41cyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz4KICAgIDwvY2lyY2xlPgo8L3N2Zz4K",
              }}
              position={{
                lat: 10,
                lng: 10
              }}
              draggable={true}
              onDragEnd={(ev) => {
                setSelectedCoordinates({
                  lat: ev.latLng.lat(),
                  lng: ev.latLng.lng()
                })
              }}
            />
          </GoogleMap>
        </Col>
      </Row>
    </div>
  </SiteModal>

  return (
    <>
      {modal}
      <div className='web'>
        <div className="search_box my-4">
          <Form onSubmit={PropertyHandler}>
            <Row className='align-items-center'>
              <Col md={4}>
                <GeoLocation
                  search={true}
                  setAddress={setAddress}
                  setAddressUrl={setAddressUrl}
                  setSelectedCoordinate={setSelectedCoordinates} />
              </Col>
              <Col md={3}>
                <input placeholder="Apartment, Floor" value={propertyDetails.floor} onChange={(e) => setPropertyDetails({
                  ...propertyDetails,
                  floor: e.target.value
                })} />
              </Col>
              <Col md={2}>
                <input type='number' placeholder="Total interior area" value={propertyDetails.area}
                  onChange={(e) => setPropertyDetails({
                    ...propertyDetails,
                    area: e.target.value
                  })} />
              </Col>
              <Col md={3} className="d-flex align-items-center justify-content-end">
                <button type='submit'>CONFIRM PROPERTY</button>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col md={12}>
              {selectedCoordinates ? (
                <GoogleMap
                  center={selectedCoordinates}
                  zoom={15}
                  mapContainerStyle={{
                    width: "100%",
                    height: "300px",
                    marginTop: "10px",
                  }}
                >
                  <MarkerF
                    icon={{
                      url: "data:image/svg+xml;base64,PHN2ZyBiYXNlUHJvZmlsZT0iZnVsbCIgd2lkdGg9Ijg2IiBoZWlnaHQ9Ijg2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPGRlZnM+CiAgICAgICAgPGZpbHRlciBpZD0iYSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4KICAgICAgICAgICAgPGZlRHJvcFNoYWRvdyBkeD0iMCIgZHk9Ii41IiBzdGREZXZpYXRpb249Ii45IiBmbG9vZC1jb2xvcj0iIzkzOTM5OCIvPgogICAgICAgIDwvZmlsdGVyPgogICAgPC9kZWZzPgogICAgPGNpcmNsZSBjeD0iNDMiIGN5PSI0MyIgcj0iOCIgZmlsbD0iIzk0YzdmZiI+CiAgICAgICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iciIgZnJvbT0iMTEiIHRvPSI0MCIgZHVyPSIycyIgYmVnaW49IjBzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIvPgogICAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9wYWNpdHkiIGZyb209IjEiIHRvPSIwIiBkdXI9IjJzIiBiZWdpbj0iMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+CiAgICA8L2NpcmNsZT4KICAgIDxjaXJjbGUgY3g9IjQzIiBjeT0iNDMiIHI9IjgiIGZpbGw9IiNmZmYiIGZpbHRlcj0idXJsKCNhKSIvPgogICAgPGNpcmNsZSBjeD0iNDMiIGN5PSI0MyIgcj0iNSIgZmlsbD0iIzAxN2FmZiI+CiAgICAgICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iciIgdmFsdWVzPSI1OzYuNTs1IiBiZWdpbj0iMHMiIGR1cj0iNC41cyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz4KICAgIDwvY2lyY2xlPgo8L3N2Zz4K",
                    }}
                    position={selectedCoordinates}
                    draggable={true}
                    onDragEnd={(ev) => {
                      setSelectedCoordinates({
                        lat: ev.latLng.lat(),
                        lng: ev.latLng.lng()
                      })
                    }}
                  />
                </GoogleMap>
              ) : null}
            </Col>
          </Row>
        </div>
      </div>

      <div className='mob'>
        <div className="search_box mt-2">
          <Form onSubmit={PropertyHandler}>
            <Row className='align-items-center'>
              <Col md={4}>
                <GeoLocation
                  search={true}
                  setAddress={setAddress}
                  setAddressUrl={setAddressUrl}
                  setSelectedCoordinate={setSelectedCoordinates}
                />
              </Col>
              {
                selectedCoordinates && <>
                  <Col md={3}>
                    <input placeholder="Apartment, Floor" value={propertyDetails.floor} onChange={(e) => setPropertyDetails({
                      ...propertyDetails,
                      floor: e.target.value
                    })} />
                  </Col>
                  <Col md={2}>
                    <input type='number' placeholder="Total interior area" value={propertyDetails.area} onChange={(e) => setPropertyDetails({
                      ...propertyDetails,
                      area: e.target.value
                    })} />
                  </Col>
                </>
              }
              <Col md={3} className="d-flex align-items-center justify-content-center">
                <button type='submit'>CONFIRM PROPERTY</button>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col md={12}>
              {selectedCoordinates ? (
                <GoogleMap
                  center={selectedCoordinates}
                  zoom={15}
                  mapContainerStyle={{
                    width: "100%",
                    height: "300px",
                    marginTop: "10px",
                  }}
                >
                  <MarkerF
                    icon={{
                      url: "data:image/svg+xml;base64,PHN2ZyBiYXNlUHJvZmlsZT0iZnVsbCIgd2lkdGg9Ijg2IiBoZWlnaHQ9Ijg2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPGRlZnM+CiAgICAgICAgPGZpbHRlciBpZD0iYSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4KICAgICAgICAgICAgPGZlRHJvcFNoYWRvdyBkeD0iMCIgZHk9Ii41IiBzdGREZXZpYXRpb249Ii45IiBmbG9vZC1jb2xvcj0iIzkzOTM5OCIvPgogICAgICAgIDwvZmlsdGVyPgogICAgPC9kZWZzPgogICAgPGNpcmNsZSBjeD0iNDMiIGN5PSI0MyIgcj0iOCIgZmlsbD0iIzk0YzdmZiI+CiAgICAgICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iciIgZnJvbT0iMTEiIHRvPSI0MCIgZHVyPSIycyIgYmVnaW49IjBzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIvPgogICAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9wYWNpdHkiIGZyb209IjEiIHRvPSIwIiBkdXI9IjJzIiBiZWdpbj0iMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+CiAgICA8L2NpcmNsZT4KICAgIDxjaXJjbGUgY3g9IjQzIiBjeT0iNDMiIHI9IjgiIGZpbGw9IiNmZmYiIGZpbHRlcj0idXJsKCNhKSIvPgogICAgPGNpcmNsZSBjeD0iNDMiIGN5PSI0MyIgcj0iNSIgZmlsbD0iIzAxN2FmZiI+CiAgICAgICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iciIgdmFsdWVzPSI1OzYuNTs1IiBiZWdpbj0iMHMiIGR1cj0iNC41cyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz4KICAgIDwvY2lyY2xlPgo8L3N2Zz4K",
                    }}
                    position={selectedCoordinates}
                    draggable={true}
                    onDragEnd={(ev) => {
                      setSelectedCoordinates({
                        lat: ev.latLng.lat(),
                        lng: ev.latLng.lng()
                      })
                    }}
                  />
                </GoogleMap>
              ) : null}
            </Col>
          </Row>
        </div>

        <div className='manual_box'>
          <h4 onClick={() => setShow(!show)}> <FaEdit /> Manually enter address</h4>
        </div>
      </div>
    </>
  )
}

export default SearchBox