import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { errorNotify, successNotify } from '../../../../../Util/toast';
import { useNavigate } from 'react-router-dom';
import { ServicesCreate } from '../../../../../Redux/Action/Services';

const CreateServices = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [packageData, setPackageData] = useState({
        servicesName: '',
        servicesPrice: '',
        servicesDsc: ''
    })
    const [priceTag, setPriceTag] = useState([
        {
            min_area: 0,
            max_area: 999,
            price: 1
        }
    ])
    const priceTagHandler = (e, index, name) => {
        const list = [...priceTag];
        list[index][name] = e.target.value;
        setPriceTag(list);
    };
    const [file, setFile] = useState(null)

    const { createServiceData, loading } = useSelector((state) => state.serviceCreate)

    useEffect(() => {
        if (createServiceData?.status === 1) {
            successNotify("Services Created!!")
            dispatch({ type: "SERVICES_CREATE_RESET" })
            navigate('/admin/services/get')
        }
    }, [createServiceData])

    const packageHandler = (e) => {
        setPackageData({
            ...packageData,
            [e.target.name]: e.target.value
        })
    }

    const packageCreateHandler = (e) => {
        e.preventDefault();

        if (packageData.servicesName.length === 0 || packageData.servicesPrice.length === 0 ||
            packageData.servicesDsc.length === 0 || !file) {
            errorNotify("Please fill up all fields")
            return
        }

        const packageForm = new FormData();
        packageForm.append('service_name', packageData.servicesName)
        packageForm.append('service_type', 1)
        packageForm.append('service_price', packageData.servicesPrice)
        packageForm.append('service_description', packageData.servicesDsc)
        packageForm.append('service_image', file);
        packageForm.append('prices', JSON.stringify(priceTag))

        dispatch(ServicesCreate(packageForm))
    }

    const handleAddClick = () => {
        const priceTagShow = priceTag[priceTag.length - 1];

        if (priceTagShow?.max_area || priceTagShow?.price) {
            if (parseInt(priceTagShow.min_area) < parseInt(priceTagShow.max_area)) {
                if (priceTagShow?.price > 0) {
                    setPriceTag([
                        ...priceTag,
                        {
                            min_area: parseInt(priceTagShow.max_area) + 1,
                            max_area: priceTag.max_area,
                            price: priceTag.price
                        }
                    ])
                }
                else {
                    errorNotify("Price must be greater than zero")
                }
            }
            else {
                errorNotify("Max SqFt must be greater than Min SqFt")
                return
            }
        }
        else {
            errorNotify("Please fill up all fields")
        }
    }

    return (
        <div className='page_responsive'>
            <div className='create_package_form'>
                <h3 className='main_head'>Create Service</h3>
                <Form onSubmit={packageCreateHandler}>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Services Image</Form.Label>
                                <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} />
                            </Form.Group>
                            {
                                file && <div className='preview_img'>
                                    <img src={URL.createObjectURL(file)} alt='' />
                                </div>
                            }
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Services Name</Form.Label>
                                <Form.Control type="text" placeholder="Services Name" name='servicesName'
                                    value={packageData.servicesName} onChange={packageHandler} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Service Price</Form.Label>
                                <Form.Control type="text" placeholder="Addon Price" name='servicesPrice'
                                    value={packageData.servicesPrice} onChange={packageHandler} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Service Description</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="Addon Description" name='servicesDsc'
                                    value={packageData.servicesDsc} onChange={packageHandler} />
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Price Tag</Form.Label>

                                {
                                    priceTag.map((c, i) => {
                                        return (
                                            <div className='price_tag' key={c}>
                                                <div>
                                                    <label>Min SqFt</label>
                                                    <Form.Control type="text" placeholder="Starting Square Feet" name='min_area'
                                                        value={c.min_area}
                                                        disabled
                                                        onChange={(e) => priceTagHandler(e, i, "min_area")}
                                                    />
                                                </div>
                                                <div>
                                                    <label>Max SqFt</label>
                                                    <Form.Control type="text" placeholder="Ending Sqaure Feet" name='max_area'
                                                        value={c.max_area}
                                                        onChange={(e) => priceTagHandler(e, i, "max_area")}
                                                    />
                                                </div>
                                                <div>
                                                    <label>Price</label>
                                                    <Form.Control type="text" placeholder="Price" name='price'
                                                        value={c.price}
                                                        onChange={(e) => priceTagHandler(e, i, "price")}
                                                    />
                                                </div>

                                                {priceTag.length - 1 === i ? (
                                                    <button type="button" onClick={handleAddClick}>Add More</button>
                                                ) : null}
                                            </div>
                                        )
                                    })
                                }
                            </Form.Group>
                        </Col>

                        <Col md={12} className='mt-3'>
                            <div className='create_btn'>
                                <button type='submit'>{loading ? <Spinner animation='border' size='sm' /> : "Create"}</button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default CreateServices