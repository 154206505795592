import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { PackageGet } from "../../Redux/Action/Packages";
import Loader from "../../Util/Loader";
import { cloudURL } from "../../Util/helper";
import { errorNotify, successNotify } from "../../Util/toast";
import { useLocation, useNavigate } from "react-router-dom";
import SiteModal from '../../Components/Modal/Modal';
import { settings, settings2 } from '../../Util/helper';
import Slider from "react-slick";
import { AddOnGet } from "../../Redux/Action/AddOn";
import { ServicesGet } from "../../Redux/Action/Services";

const Services = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [packageData, setPackageData] = useState({})
  const [savePackage, setSavePackage] = useState({})
  const [show, setShow] = useState(false)
  const [tab, setTab] = useState("INCLUDED")
  const [addOns, setAddOns] = useState([])
  const { getPackagesData, loading } = useSelector((state) => state.packageGet)
  const { getAddOnData, loading: addOnLoading } = useSelector((state) => state.addOnGet)
  const { getServicesData, loading: serviceLoading } = useSelector((state) => state.serviceGet)

  useEffect(() => {
    dispatch(PackageGet(state.propertyData.propertyDetails.area))
    dispatch(AddOnGet())
    dispatch(ServicesGet())
  }, [])

  const packageSelectHandler = (p) => {
    if (Object.keys(packageData).length === 0) {
      setPackageData(p)
      successNotify("Package Selected!")
    }
    else {
      errorNotify("Package Already Selected")
    }
  }

  const packageDeleteHandler = () => {
    setPackageData({})
    successNotify("Package Removed!")
  }

  const goToService = () => {
    if (Object.keys(packageData).length === 0) {
      errorNotify("Please Select Package")
      return
    }
    {
      packageData.Prices?.length === 0 ?
        navigate('/services', { state: { id: packageData.id, packageDetail: state.propertyData, addOnData: addOns, price: packageData.package_price } })
        :
        navigate('/services', { state: { id: packageData.id, packageDetail: state.propertyData, addOnData: addOns, price: packageData?.Prices[0]?.price } })
    }
  }

  const packageModal = (p) => {
    setShow(!show)
    setSavePackage(p)
  }

  function calculateTotalPrice(packagePrice, addOns) {
    if (packagePrice) {
      let totalPrice = parseInt(packagePrice) || 0;
      addOns.forEach((addon) => {
        if (addon.addon_price) {
          totalPrice += parseInt(addon.addon_price) || 0;
        }
        else {
          totalPrice += parseInt(addon.service_price) || 0;
        }
      });
      return totalPrice;
    }
    else {
      let totalPrice = 0;
      addOns.forEach((addon) => {
        if (addon.addon_price) {
          totalPrice += parseInt(addon.addon_price) || 0;
        }
        else {
          totalPrice += parseInt(addon.service_price) || 0;
        }
      });
      return totalPrice;
    }
  }

  const addAddOnsHandler = (data) => {
    for (let value in addOns) {
      if (addOns[value].id === data.id) {
        errorNotify("This Addons is already added!")
        return;
      }
    }

    setAddOns((prev) => [...prev, data])
    successNotify("AddOns Added Successfully!")
  }

  const addOnDeleteHandler = (i) => {
    const newAddOns = addOns.filter((_, index) => index !== i);
    setAddOns(newAddOns)
  }

  console.log(savePackage)

  const modal = <SiteModal title=" " show={show} size="lg" onCloseModal={() => setShow(!show)} showIcon={true} >
    <div className='package_modal'>
      <img src={`${cloudURL}/packages/${savePackage.package_banner}`} alt="" />

      <div className="package_detail">
        <div className="d-flex justify-content-between align-items-center">
          <h4 style={{ fontWeight: "700" }}>{savePackage.package_name}</h4>

          <div className="num_of_services" style={{ width: "fit-content", gap: "10px" }}>
            <h6>Services</h6>
            <h6>{savePackage?.Services?.length}</h6>
          </div>
        </div>

        <p className="service_info mt-4">
          {savePackage.package_description}
        </p>

        <hr />

        <div className="service_tabs mt-5">
          <button className={tab === 'INCLUDED' ? "active" : ""} onClick={() => setTab("INCLUDED")}>What's Included</button>
          <button className={tab === 'ABOUT' ? "active" : ""} onClick={() => setTab("ABOUT")}>About Package</button>
        </div>

        {
          tab === 'ABOUT' && <div>

            <div>
              <h5 style={{
                fontSize: "27px",
                marginBottom: "10px",
                fontWeight: "600",
                textTransform: "uppercase",
                textAlign: "center"
              }}>Gallery</h5>
              <Slider {...settings}>
                {
                  savePackage?.Media?.length > 0 ? savePackage?.Media?.map((i, index) => {
                    return (
                      <div key={index} style={{ position: "relative" }}>
                        <img src={`${cloudURL}/gallery/${i.filename}`} alt=""
                          style={{ maxWidth: "100%", height: "400px", objectFit: "cover", width: "100%" }} />
                      </div>
                    )
                  }) : <h5 className="text-center mt-3">No Gallery Images</h5>
                }
              </Slider>
            </div>
          </div>
        }
        {
          tab === 'INCLUDED' && <div className="package_included">
            <h2 style={{ fontSize: "30px", fontWeight: "700" }} className="mb-3">In Package</h2>
            <Row className="benefits">
              {
                savePackage?.Services?.map((a) => (
                  <Col md={3} xs={6} key={a.id}>
                    <img src={`${cloudURL}/services/${a.service_image}`} alt="" style={{ width: "100%", height: "100px", borderRadius: "10px" }} />
                    <h6 style={{ fontSize: "17px", marginTop: "8px", fontWeight: "600" }}>{a.service_name}</h6>
                    <p style={{fontSize: "15px", color: "#f48847"}}>${a.service_price}.00</p>
                  </Col>
                ))
              }
            </Row>
          </div>
        }

        <hr />

        <div className="service_main p-4">
          <div>
            <h2>Select Add-ons</h2>

            {
              addOnLoading ? <div>
                <Loader />
              </div> :
                <Row className="mt-3">
                  <Slider {...settings2}>
                    {
                      getAddOnData?.data?.rows?.map((a, i) => {
                        return (
                          <div className="addon_div" key={i}>
                            <img src={`${cloudURL}/addons/${a.addon_image}`} alt="" style={{ height: "150px" }} />
                            <h6 className="my-2" style={{ fontSize: "17px" }}>{a.addon_name}</h6>
                            <p style={{ color: "#f48847" }}>${a.addon_price}.00</p>
                            <button
                              onClick={() => addAddOnsHandler(a)}
                            >+</button>
                          </div>
                        )
                      })
                    }
                  </Slider>
                </Row>
            }
          </div>
        </div>

        <button className="select_package" onClick={() => {
          packageSelectHandler(savePackage)
          setShow(!show)
        }}>Add to Order</button>
      </div>

    </div>
  </SiteModal>

  return (
    <div className="py-5" style={{ backgroundColor: "#f8f9fa" }}>
      {modal}
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            {
              loading ? <Loader /> :
                <Row className="justify-content-center">
                  {
                    getPackagesData?.data?.rows?.length > 0 ? getPackagesData?.data?.rows?.map((p) => {
                      return (
                        <Col md={11}>
                          <div className="service_main" key={p.id}>
                            <div className="saving"><h6>SAVING ${p?.package_saving}.00</h6></div>
                            <img src={`${cloudURL}/packages/${p.package_banner}`} alt="" />
                            <div className="mx-4 my-2">
                              <h2>{p.package_name}</h2>
                              <p className="service_info">
                                {p.package_description}
                              </p>
                              <hr />
                              <div>
                                <div className="benefits d-flex" style={{ flexWrap: "wrap", gap: "10px" }}>
                                  {
                                    p?.Services?.map((a) => (
                                      <div className="d-flex align-items-center" key={a.id}>
                                        <img src="/images/check.png" alt="" />
                                        <p>{a.service_name}</p>
                                      </div>
                                    ))
                                  }
                                </div>
                              </div>
                              <hr />
                              <div>
                                <Row className="justify-content-between">
                                  <Col md={4}>
                                    <div className="num_of_services" onClick={() => packageModal(p)}>
                                      <h6>Services</h6>
                                      <h6>{p.Services.length}</h6>
                                    </div>

                                  </Col>
                                  <Col md={6}>
                                    <div className="service_amount" style={{ gap: "10px" }}>
                                      {
                                        p.Prices?.length === 0 ? <h6>${p.package_price}.00</h6> : <h6>${p?.Prices[0]?.price}.00</h6>
                                      }
                                      <button type="button" className="select_package_btn" onClick={() => packageSelectHandler(p)}>+</button>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )
                    }) : <h4 style={{ textAlign: "center", fontWeight: "600" }}>No Package Found</h4>
                  }
                </Row>
            }

            <div className="service_main p-4">
              <div>
                <h2>A La Carte</h2>

                {

                  serviceLoading ? <div>
                    <Loader />
                  </div> :
                    <Row className="mt-3" style={{ gap: "15px 0" }}>
                      {
                        getServicesData?.data?.rows?.map((a) => {
                          return (
                            <Col md={4} key={a.id}>
                              <div className="addon_div">
                                <img src={`${cloudURL}/services/${a.service_image}`} alt="" />
                                <h6>{a.service_name}</h6>
                                <p style={{ color: "#f48847" }}>${a.service_price}.00</p>
                                <button onClick={() => addAddOnsHandler(a)}>+</button>
                              </div>
                            </Col>
                          )
                        })
                      }
                    </Row>
                }
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="amount_container">
              <div>
                <div>
                  <h5>- {state.propertyData.address}</h5>
                </div>
                <hr />
                {
                  Object.keys(packageData).length === 0 ?
                    <p className="text-center">No Package Selected</p> :
                    <div className="d-flex justify-content-between">
                      <h6 style={{ fontWeight: "600" }}>{packageData.package_name}</h6>

                      {
                        packageData.Prices?.length === 0 ?
                          <h6 style={{ fontWeight: "600" }}>
                            ${packageData.package_price}.00  <span onClick={packageDeleteHandler}>
                              <img src="/images/delete_icon.png" alt="" style={{ cursor: "pointer", width: "25px", paddingLeft: "10px" }} /></span> </h6>
                          : <h6>${packageData?.Prices[0]?.price}.00 <span onClick={packageDeleteHandler}>
                            <img src="/images/delete_icon.png" alt="" style={{ cursor: "pointer", width: "25px", paddingLeft: "10px" }} /></span></h6>
                      }
                    </div>
                }

                <hr />

                {
                  addOns.map((a, i) => {
                    return (
                      <div className="d-flex justify-content-between mb-2" key={a.id}>
                        <h6 style={{ fontWeight: "600" }}>{a.addon_name ? a.addon_name : a.service_name}</h6>
                        <h6 style={{ fontWeight: "600" }}>${a.addon_price ? a.addon_price : a.service_price}.00
                          <span onClick={() => addOnDeleteHandler(i)}>
                            <img src="/images/delete_icon.png" alt="" style={{ cursor: "pointer", width: "25px", paddingLeft: "10px" }} /></span>
                        </h6>
                      </div>
                    )
                  })
                }
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <h6>TOTAL AMOUNT</h6>
                <h6 style={{ fontWeight: "600" }}>
                  {
                    Object.keys(packageData).length !== 0 ? <>
                      {
                        packageData.Prices?.length === 0 ?
                          <> $ {calculateTotalPrice(packageData.package_price, addOns)}.00 </> :
                          <>
                            $ {calculateTotalPrice(packageData?.Prices[0]?.price, addOns)}.00
                          </>
                      }
                    </> : null
                  }
                </h6>
              </div>
              <hr />
              <button onClick={goToService}>CONTINUE</button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Services;