import axios from "axios";
import { CurrentUserInfo, getToken } from "../../Util/helper";
import api from '../../Util/Interceptor';

export const PackageGet = (area) => async (dispatch) => {
    try {
        dispatch({
            type: "PACKAGE_GET_REQUEST",
        });

        let d;
        if (area) {
            d = await axios.get(`admin/package/list?area=${area}`);
        }
        else {
            d = await axios.get('package/list');
        }

        dispatch({
            type: "PACKAGE_GET_SUCCESS",
            payload: d.data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "PACKAGE_GET_FAILED",
            payload: e?.response?.data?.message,
            success: false,
        });
    }
};

export const PackageGetById = (id) => async (dispatch) => {

    try {
        dispatch({
            type: "PACKAGE_GET_ID_REQUEST",
        });

        let d;
        if (CurrentUserInfo()) {
            d = await axios.get(`package/${id}`);
        }
        else {
            d = await axios.get(`package/${id}`);
        }

        dispatch({
            type: "PACKAGE_GET_ID_SUCCESS",
            payload: d.data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "PACKAGE_GET_ID_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};

export const PackageCreate = (d) => async (dispatch) => {

    try {
        dispatch({
            type: "PACKAGE_CREATE_REQUEST",
        });

        const { data } = await api.post(`admin/package`, d, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: getToken()
            }
        });

        dispatch({
            type: "PACKAGE_CREATE_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "PACKAGE_CREATE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};

export const PackageUpdate = (d, id) => async (dispatch) => {

    try {
        dispatch({
            type: "PACKAGE_UPDATE_REQUEST",
        });

        const { data } = await api.put(`admin/package/${id}`, d, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: getToken()
            }
        });

        dispatch({
            type: "PACKAGE_UPDATE_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "PACKAGE_UPDATE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};

export const PackageDelete = (id) => async (dispatch) => {

    try {
        dispatch({
            type: "PACKAGE_DELETE_REQUEST",
        });

        const { data } = await api.delete(`admin/package/${id}`);

        dispatch({
            type: "PACKAGE_DELETE_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "PACKAGE_DELETE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};