import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from 'react'
import Header from "./Components/Header/Header";
import "./Styles/global.css"
import Home from "./Container/MainPages/Home";
import Packages from "./Container/MainPages/Packages";
import Login from "./Container/Auth/Login/Login";
import AdminLayout from "./Layout/AdminLayout/AdminLayout";
import { adminRoutes } from "./Container/Admin/Routes";
import MainLayout from "./Layout/MainLayout/MainLayout";
import { ToastContainer } from "react-toastify";
import Services from "./Container/MainPages/services";
import Calender from "./Container/MainPages/Calender";
import Checkout from "./Container/MainPages/Checkout";

const App = () => {

  const adminLayout = (
    <Route path={"/admin"} element={<AdminLayout />}>
      {adminRoutes.map((item) => (
        <Route key={item.path} path={item.path} element={item.component} />
      ))}
    </Route>
  );

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Header />

        <Routes>
          <Route path={"/"} element={<MainLayout />}>
            <Route path='/' element={<Home />} />
            <Route path='/login' element={<Login />} />
            <Route path='/packages' element={<Packages />} />
            <Route path='/services' element={<Services />} />
            <Route path='/scheduling' element={<Calender />} />
            <Route path='/checkout' element={<Checkout />} />
          </Route>

          {adminLayout}
        </Routes>
      </Router>
    </div>
  )
}
export default App;