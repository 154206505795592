import axios from "axios";
import { CurrentUserInfo, getToken } from "../../Util/helper";
import api from '../../Util/Interceptor';

export const AddOnGet = () => async (dispatch) => {

    try {
        dispatch({
            type: "ADD_ON_GET_REQUEST",
        });

        let d;
        if (CurrentUserInfo()) {
            d = await api.get('addon/list');
        }
        else {
            d = await axios.get('addon/list');
        }

        dispatch({
            type: "ADD_ON_GET_SUCCESS",
            payload: d.data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "ADD_ON_GET_FAILED",
            payload: e?.response?.data?.message,
            success: false,
        });
    }
};

export const AddonCreate = (d) => async (dispatch) => {

    try {
        dispatch({
            type: "ADDON_CREATE_REQUEST",
        });

        const { data } = await api.post(`admin/addon`, d, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: getToken()
            }
        });

        dispatch({
            type: "ADDON_CREATE_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "ADDON_CREATE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};

export const AddonDelete = (id) => async (dispatch) => {

    try {
        dispatch({
            type: "ADDON_DELETE_REQUEST",
        });

        const { data } = await api.delete(`admin/addon/${id}`);

        dispatch({
            type: "ADDON_DELETE_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "ADDON_DELETE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};

export const AddOnGetById = (id) => async (dispatch) => {

    try {
        dispatch({
            type: "ADD_ON_GET_ID_REQUEST",
        });

        let { data } = await api.get(`admin/addon/${id}`);

        dispatch({
            type: "ADD_ON_GET_ID_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "ADD_ON_GET_ID_FAILED",
            payload: e?.response?.data?.message,
            success: false,
        });
    }
};

export const AddonUpdate = (d, id) => async (dispatch) => {

    try {
        dispatch({
            type: "ADDON_UPDATE_REQUEST",
        });

        const { data } = await api.put(`admin/addon/${id}`, d, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: getToken()
            }
        });

        dispatch({
            type: "ADDON_UPDATE_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e) {
        dispatch({
            type: "ADDON_UPDATE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};