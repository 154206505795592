import React, { useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { PackageDelete, PackageGet } from "../../../../Redux/Action/Packages";
import Loader from "../../../../Util/Loader";
import { cloudURL } from "../../../../Util/helper";
import './Packages.css';
import { useNavigate } from "react-router-dom";
import { successNotify } from "../../../../Util/toast";

const Services = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getPackagesData, loading } = useSelector((state) => state.packageGet)
  const { packageDeleteData, loading: deleteLoading } = useSelector((state) => state.packageDelete)

  useEffect(() => {
    if (packageDeleteData?.status) {
      dispatch({ type: "PACKAGE_DELETE_RESET" })
      successNotify("Deleted Successfully!")
    }
  }, [packageDeleteData?.status])

  useEffect(() => {
    dispatch(PackageGet())
  }, [packageDeleteData])

  const deleteHandler = (id) => {
    dispatch(PackageDelete(id))
  }

  return (
    <div className="page_responsive">
      <Container>
        <div className="create_package">
          <button onClick={() => navigate('/admin/packages/create')}>Create Package</button>
        </div>
        <Row>
          {
            loading ? <Loader /> :
              <>
                {
                  getPackagesData?.data?.rows?.length > 0 ? getPackagesData?.data?.rows?.map((p) => {
                    return (
                      <Col md={6} key={p.id}>
                        <div className="service_main" style={{ backgroundColor: "#fff" }}>
                          <div className="saving"><h6>SAVING ${p?.package_saving}.00</h6></div>
                          <img src={`${cloudURL}/packages/${p?.package_banner}`} alt="" />
                          <div className="mx-4 my-2">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <h2>{p?.package_name}</h2>
                              <h6 style={{ color: "#f48847" }}>${p?.Prices[0]?.price}.00</h6>
                            </div>

                            <p className="service_info">
                              {p?.package_description}
                            </p>
                            <hr />
                            <div>
                              <div className="gap-4 benefits d-flex">
                                {
                                  p?.Services?.map((a) => (
                                    <div className="d-flex align-items-center" key={a.id}>
                                      <img src="/images/check.png" alt="" />
                                      <p>{a.service_name}</p>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                            <hr />

                            <div className="text-end d-flex gap-3 justify-content-end">
                              <button
                                style={{ padding: "5px 20px", fontSize: "15px", right: "110px" }}
                                onClick={() => navigate(`/admin/packages/edit/${p.id}`)}>Edit</button>
                              <button style={{ padding: "5px 20px", fontSize: "15px" }} onClick={() => deleteHandler(p.id)}>
                                {
                                  deleteLoading ? <Spinner animation="border" size="sm" /> : "Delete"
                                }
                              </button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                  }) : <h5 className="text-center">No Package Found</h5>
                }
              </>
          }
        </Row>
      </Container>
    </div>
  );
};

export default Services;
